// crop content
$(window).on("load resize", function () {
	descriptionCrop();
});

function descriptionCrop() {
	var box_desc = $(".js--crop");
	var box_crop_height = getComputedStyle(document.documentElement).getPropertyValue('--crop-height');

	box_desc.each(function () {
		var btn_more = $(".js--crop-btn[data-content=" + $(this).attr('id') + "]");
		var box_desc_height = $(this).prop("scrollHeight"); // real height of box

		if (box_desc_height != null && box_desc_height > box_crop_height) {
			$(this).addClass('description--crop');
			btn_more.addClass('is--active');
		} else {
			$(this).removeClass('description--crop');
			btn_more.removeClass('is--active');
		}
	})
}

$(".js--crop-btn").click(function () {
	var box_desc = $("#" + $(this).attr('data-content'));
	var btn_more = $(this);
	box_desc.toggleClass('description--open');
	btn_more.toggleClass('is--open');
});