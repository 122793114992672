$(document).ready(function() {

  // toggle responsive menu
  $('.navigation__toggle').click(function () {
    if($('body').hasClass('show-resp-nav')) {
      $('.navigation__toggle').attr("aria-expanded", "false");
    } else {
      $('.navigation__toggle').attr("aria-expanded", "true");
    }
    $('.navigation').fadeToggle('fast');
    $('.navigation__toggle').toggleClass('is--active');
    $('body').toggleClass('show-resp-nav');
  });

});



// hover/clickabel dropdown nav
document.addEventListener("DOMContentLoaded", function() {
  if (window.innerWidth > 992) {
    document.querySelectorAll('.navigation__list .navigation__item.dropdown a').forEach(function(everyitem) {
      everyitem.addEventListener('click', function(e) {
        location.href = this.href;
      });
    });
    document.querySelectorAll('.navigation__list .navigation__item').forEach(function(everyitem) {
      everyitem.addEventListener('mouseover', function(e) {
        let el_link = this.querySelector('a[data-bs-toggle]');
        if(el_link != null) {
          let nextEl = el_link.nextElementSibling;
          document.querySelector('body').classList.add('show-nav');
          el_link.setAttribute('aria-expanded', true);
          el_link.classList.add('show');
          nextEl.classList.add('show');
        }
      });
      everyitem.addEventListener('mouseleave', function(e) {
        let el_link = this.querySelector('a[data-bs-toggle]');
        if(el_link != null) {
          let nextEl = el_link.nextElementSibling;
          document.querySelector('body').classList.remove('show-nav');
          el_link.setAttribute('aria-expanded', false);
          el_link.classList.remove('show');
          nextEl.classList.remove('show');
        }
      })
    });
  } else {
    document.querySelectorAll('.navigation__list .navigation__item a').forEach(function(everyitem) {
      everyitem.addEventListener('click', function(e) {
        location.href = this.href;
      });
    });
  }
});