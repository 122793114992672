$(document).ready(function() { 
  
  // wide carousel
  $('.carousel--7').slick({
    lazyLoad: 'ondemand',
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 7,
    slidesToScroll: 3,
    prevArrow:"<button type='button' class='slick-prev slick-arrow' aria-label='Předchozí'><svg aria-hidden='true' fill='currentColor' width='20' height='20'><use xlink:href='#icon-arrow-left'></use></svg></button>",
    nextArrow:"<button type='button' class='slick-next slick-arrow' aria-label='Další'><svg aria-hidden='true' fill='currentColor' width='20' height='20'><use xlink:href='#icon-arrow-right'></use></svg></button>",
    responsive: [
      {
        breakpoint: 1499,
        settings: {
          slidesToShow: 6,
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.25,
          slidesToScroll: 2,
          arrows: false
        }
      },
      /*{
        breakpoint: 480,
        settings: "unslick",
      }*/
    ]
  });

  $('.carousel--6').slick({
    lazyLoad: 'ondemand',
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 3,
    prevArrow:"<button type='button' class='slick-prev slick-arrow' aria-label='Předchozí'><svg aria-hidden='true' fill='currentColor' width='20' height='20'><use xlink:href='#icon-arrow-left'></use></svg></button>",
    nextArrow:"<button type='button' class='slick-next slick-arrow' aria-label='Další'><svg aria-hidden='true' fill='currentColor' width='20' height='20'><use xlink:href='#icon-arrow-right'></use></svg></button>",
    responsive: [
      {
        breakpoint: 1499,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 991,
        settings: "unslick",
      }
    ]
  });

  // banner carousel
  $('.carousel--1').slick({
    lazyLoad: 'ondemand',
    dots: false,
    infinite: false,
    speed: 550,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow:"<button type='button' class='slick-prev slick-arrow' aria-label='Předchozí'><svg aria-hidden='true' fill='currentColor' width='20' height='20'><use xlink:href='#icon-arrow-left'></use></svg></button>",
    nextArrow:"<button type='button' class='slick-next slick-arrow' aria-label='Další'><svg aria-hidden='true' fill='currentColor' width='20' height='20'><use xlink:href='#icon-arrow-right'></use></svg></button>",
    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false
        }
      },
    ]
  });

});