// toggle responsive search
const search_selector = "#search";
const search_box = document.querySelector(search_selector);
const search_button = document.querySelector("#search-toggle");

if (search_box && search_button) {
	document.addEventListener("click", (e) => {
		const isClosest = e.target.id;
		if (!isClosest && search_box.classList.contains("is--active")) {
			search_box.classList.remove("is--active");
		}
	});

	search_button.addEventListener("click", () => {
		if (!search_box.classList.contains("is--active")) {
			search_box.classList.add("is--active");
			document.getElementById("search-input").focus();
		}
	});
}