$(document).ready(function () {

	// goto scroll
	$(".js-goto").on('click', function (e) {
		//e.preventDefault();
		var el = $(this).attr('data-to');
		$('html,body').animate({
			scrollTop: $(el).offset().top
		}, 1000);
	});



	// file input avatar image
	$(".js-avatar").on('click', function (e) {
		$('#file-input').click();
	});



	// close address on reg. frm
	$(".js-close-address").on('click', function (e) {
		$('#address').click();
	});

});



// form input file
let file_input = document.getElementById("file-input");
let file_name = document.getElementById("file-name");
let file_preview = document.getElementById("file-preview");

if (file_input && file_name) {
	// after upload change name file
	file_input.addEventListener("change", () => {
		let file_image = document.querySelector("input[type=file]").files[0];
		file_name.innerText = file_image.name;
	});
}

if (file_input && file_preview) {
	// after upload change preview file
	file_input.addEventListener("change", () => {
		const [file] = file_input.files
		if (file) {
			file_preview.src = URL.createObjectURL(file)
			file_preview.srcset = URL.createObjectURL(file)
		}
	});
}



// change position section news in DOM when resize
if (
	document.getElementById("sec-aktuality") &&
	document.getElementById("sec-pripravujeme") &&
	document.getElementById("sec-recenze")
) {
	changePosNews();
	window.onresize = function () {
		changePosNews();
	}
}

function changePosNews() {
	var aktuality = document.getElementById("sec-aktuality");
	var pripravujeme = document.getElementById("sec-pripravujeme");
	var recenze = document.getElementById("sec-recenze");

	if (window.innerWidth <= 1199) {
		var parentDiv = pripravujeme.parentNode;
		parentDiv.insertBefore(aktuality, pripravujeme);
	} else {
		var parentDiv = recenze.parentNode;
		parentDiv.insertBefore(aktuality, recenze);
	}
}