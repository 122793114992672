$(document).ready(function() {
  
  // popup single image
  $('.js-image').magnificPopup({
    type: 'image',
    closeOnContentClick: true,
    image: {
      tLoading: 'Načítání...',
      tError: '<a href="%url%">Obrázek</a> nelze načíst.',
    }
  });


  // popup gallery
  $('.js-gallery').magnificPopup({
    delegate: 'a',
    type: 'image',
    gallery: {
      enabled: true,
      tPrev: 'Předchozí',
      tNext: 'Další', 
      tCounter: '<span class="mfp-counter">%curr% / %total%</span>',
    },
    image: {
      tLoading: 'Načítání...',
      tError: '<a href="%url%">Obrázek</a> nelze načíst.',
    }
  });


  // popup iframe
  $('.js-iframe').magnificPopup({
		type: 'iframe',
		preloader: false,
    iframe: {
      tLoading: 'Načítání...',
      tError: '<a href="%url%">Náhled</a> nelze načíst.',
    },
    callbacks: {
      beforeOpen: function() {
        $('body').addClass('mfp-zoom-out-cur');
      },
      beforeClose: function() {
        $('body').removeClass('mfp-zoom-out-cur');
      },
    }
  });

});