$(document).ready(function() {

  // add emoticons to comment
  $('.button--emoticon').on('click', function() {
    var emoticon = $(this).attr('data-emoticon');
    var textarea = $('#comment-text');
    
    // Get the current cursor position
    var cursorPos = textarea.prop('selectionStart');
    
    // Get the text before and after the cursor position
    var textBefore = textarea.val().substring(0, cursorPos);
    var textAfter = textarea.val().substring(cursorPos, textarea.val().length);
    
    // Update the textarea value
    textarea.val(textBefore + emoticon + textAfter);
    
    // Restore the cursor position
    var newCursorPos = cursorPos + emoticon.length;
    textarea.prop('selectionStart', newCursorPos);
    textarea.prop('selectionEnd', newCursorPos);
    textarea.focus();
  });

  
  // reply comment 
  $('.js-reply').click(function() {
    $('#reply-label').show();
    $('#reply-user').html($(this).attr('data-reply-user'));
    $('#comment-reply').val($(this).attr('data-reply-id'));
  });

  $('#js-reply-reset').click(function() {
    $('#reply-label').hide();
    $('#reply-user').html('');
    $('#comment-reply').val('');
  });

});